import React, { Component, ReactNode } from 'react'
import styled from 'styled-components'

import { LightBoxConsumer } from './LightBoxProvider'

type LightBoxItemProps = {
  children: ReactNode
  context?: any
  image?: any
}

const Clickable = styled.button`
  border: 0;
  display: block;
  height: 100%;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
`

const noop = () => {}

class _LightBoxItem extends Component<LightBoxItemProps> {
  state = {
    registered: false,
  }

  componentDidMount() {
    const { context, image } = this.props
    const { registerImage = noop } = context
    registerImage(image)
  }

  render() {
    const { children, image, context } = this.props
    const { openLightBox = noop } = context
    return (
      <Clickable type="button" tabIndex={0} onClick={() => openLightBox(image)}>
        {children}
      </Clickable>
    )
  }
}

export const LightBoxItem = props => (
  <LightBoxConsumer>
    {context => <_LightBoxItem {...props} context={context} />}
  </LightBoxConsumer>
)
