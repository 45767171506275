export const productTheme = {
  'blue-lagoon': ['#5B839B', '#50A7BA'],
  'retreat-spa': ['#626971', '#9C747F'],
  'retreat-hotel': ['#62666F', '#233030'],
  'silica-hotel': ['#9A8E88', '#8F9196'],
}

// Gradient map used by Granim and portals
export const gradients = {
  blueLagoon: ['#4DA1B9', '#EFEEEA'],
  blueLagoonBlue1: ['#F7F7F7', '#AAD5E5'],
  blueLagoonBlue2: ['#F9FBFC', '#CFE6F1'],
  blueLagoonSoft: ['#FAF9F8', '#EAF0F4'],
  bottleGreen: ['#576A6C', '#403F3D'],
  nightVisit: ['#50555B', '#908C8B'],
  retreatHotel: ['#6F747F', '#233030'],
  retreatHotelLevel1: ['#7A7F8A', '#3D4A4A'],
  retreatHotelLevel2: ['#606570', '#162323'],
  retreatHotelLevel3: ['#464B56', '#0A1717'],
  retreatHotelSmoke0: ['#717682', '#646a73'],
  retreatHotelSmoke1: ['#717682', '#646a73'],
  retreatHotelSmoke2: ['#5A5F67', '#31393C'],
  retreatHotelSmoke3: ['#282E31', '#151C1D'],
  retreatHotelSmoke4: ['#1B2124', '#080F10'],
  retreatSpa: ['#626971', '#9C747F'],
  silicaHotel: ['#A0A4B3', '#A48E85'],
  silicaPink: ['#F9F9F9', '#E0D8D5'],
  softBlue: ['#DEE9EF', '#F0F0ED'],
  spaPurple1: ['#626971', '#696A72'],
  spaPurple2: ['#52555B', '#655960'],
  spaPurple3: ['#494247', '#58454B'],
  spaPurple4: ['#392D31', '#3E2E32'],
  supportSilver: ['#FDFCFB', '#E2E5E7'],
  topicGreen: ['#D8E7E6', '#F5F6F5'],
  topicPurple: ['#FDF9F2', '#DBDEE1'],
  topicYellow: ['#FAEACF', '#E9E8E8'],
  transport: ['#638495', '#4D6C7E'],
  lagoonSuite: ['#6C8897', 'A3A9AA'],
  softGradient1: ['#DEE9EF', '#F0F0ED'],
  softGradient2: ['#FDF9F2', '#DBDEE1'],
  softGradient3: ['#FAEACF', '#E9E8E8'],
  softGradient4: ['#F9F9F9', '#E0D8D5'],
  softGradient5: ['#D8E7E6', '#F5F6F5'],
  softGradient6: ['#FDFCFB', '#E2E5E7'],
  g1: ['#52A5B8', '#6793AE'],
  g2: ['#AFCFDE', '#C1C3B7'],
  g3: ['#B39EA5', '#638495'],
  g4: ['#F1EEE9', '#6E7A86'],
  g5: ['#586470', '#B4A69B'],
  g6: ['#AFCFDE', '#BD9B80'],
  g7: ['#50555B', '#908C8B'],
  g8: ['#AAAC9E', '#7E9293'],
  g9: ['#8FB8BA', '#50555B'],
  g10: ['#50555B', '#908C8B'],
  activityVolcano: ['#797E89', '#233030'],
  // default and fallback
  white: ['#FFFFFF', '#FFFFFF'],

  branding: {},
}

// used for portals and their buttons
gradients.branding = {
  blueLagoon: {
    backgroundColors: gradients.blueLagoon,
    backgroundDegrees: '138deg',
    blendMode: 'multiply',
  },
  retreatSpa: {
    backgroundColors: gradients.retreatSpa,
    backgroundDegrees: '-45deg',

    blendMode: 'multiply',
  },
  silicaHotel: {
    backgroundColors: gradients.silicaHotel,
    backgroundDegrees: '-45deg',
    blendMode: 'multiply',
  },
  retreatHotel: {
    backgroundColors: gradients.retreatHotel,
    backgroundDegrees: '-43deg',
    blendMode: 'overlay',
  },
  mossRestaurant: {
    backgroundColors: gradients.g7,
    backgroundDegrees: '121deg',
    blendMode: 'multiply',
  },
  lavaRestaurant: {
    backgroundColors: gradients.g2,
    backgroundDegrees: '150deg',
    blendMode: 'multiply',
  },
  spaRestaurant: {
    backgroundColors: gradients.g5,
    backgroundDegrees: '311deg',
    blendMode: 'multiply',
  },
  cafe: {
    backgroundColors: gradients.g4,
    backgroundDegrees: '91deg',
    blendMode: 'multiply',
  },
}
