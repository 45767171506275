import { useWindowSize } from '@react-hookz/web'

import { breakpoints } from '../constants/breakpoints'
import { base } from '../constants/sizes'

// Returns a number that changes according to scaling of window width
// for usage on the JS side, based on the 'basis' input.
export const useResponsive = (basis = 1): number => {
  if (typeof window === 'undefined') {
    return base
  }

  const { width } = useWindowSize()

  const fromWidth = breakpoints.sm
  const toWidth = breakpoints.lg

  const fromBase = 12 * basis
  const toBase = 16 * basis

  const ratio = (width - fromWidth) / (toWidth - fromWidth)
  const scaleNum = fromBase + (toBase - fromBase) * ratio

  return scaleNum
}
