import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

export const Container = styled.div`
  position: relative;
  padding: 40px 24px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(135deg, #fdfcfb 0%, #e2e5e7 100%);
    z-index: -1;

    ${media.md(css`
      left: -32px;
      right: -32px;
    `)}
  }

  ${media.md(css`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => `${theme.spacing[4]} 0`};
  `)}

  ${media.lg(css`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => `${theme.spacing[6]} 0`};
  `)}
`

export const ContainerInner = styled.div`
  ${media.md(css`
    width: ${mixins.span({ columns: 6, gutters: 9 })};
    margin: 0 auto;
  `)}
`

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`

export const Divider = styled.div`
  width: 80px;
  height: 4px;
  background-color: ${colors.fountainBlue};
  margin-bottom: 32px;

  ${media.md(css`
    margin-top: ${({ theme }) => theme.spacing[1]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  `)}
`

export const EmailWrap = styled.div`
  ${media.md(css`
    display: flex;
    align-items: flex-end;

    > {
      div {
        flex: 1;
        margin: 0;
        margin-right: 32px;
      }
      button {
        min-width: 185px;
        height: 56px;
      }
    }
  `)}
`

export const TopWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
})

export const Label = styled(Type)({
  fontWeight: 'bold',
  marginBottom: '8px',
  display: 'block',
})

export const StyledButton = styled(Button)({
  width: '100%',
  margin: '24px 0 0',
})

export const InputGroup = styled.div`
  margin-bottom: 32px;

  .errors {
    color: ${colors.errorRed};
  }
`

export const CheckboxSectionContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;

  ${media.md(css`
    margin-top: ${({ theme }) => theme.spacing[2]};
  `)}

  @media (min-width: 768px) and (orientation: portrait) {
    max-width: 80%;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  cursor: pointer;
  user-select: none;
  align-items: center;
`

// export const Button = styled.button`
//   width: 100%;
//   color: #fff;
//   padding: 18px 0 16px;
//   background: linear-gradient(90deg, #6793ae 0%, #50a7ba 100%);
//   font-weight: 500;
//   cursor: pointer;
//   font-size: 14px;
//   margin: 24px 0 0;

//   ${media.md(css`
//     font-size: 16px;
//     padding: ${between(18, 24)} 0 ${between(16, 22)};
//   `)}
// `

export const Form = styled.form`
  position: relative;
  z-index: 1;
`

export const Loader = styled.div`
  border-radius: 50%;
  width: 19px;
  height: 19px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: loading 1.8s infinite ease-in-out;
  animation: loading 1.8s infinite ease-in-out;
  top: -24px;

  &:before,
  &:after {
    border-radius: 50%;
    width: 19px;
    height: 19px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loading 1.8s infinite ease-in-out;
    animation: loading 1.8s infinite ease-in-out;
    content: '';
    position: absolute;
    top: 0;
  }

  color: #ffffff;
  font-size: 10px;
  margin: 0px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &:after {
    left: 3.5em;
  }

  @-webkit-keyframes loading {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes loading {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`

export const MessageDialogWrapper = styled.div`
  position: relative;
  .error {
    font-weight: bold;
    color: ${colors.errorRed};
  }
`
export const SuccessIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  ${media.md(css`
    display: inline-block;
    margin-right: 32px;
    margin-bottom: 0;
  `)}
`
export const ErrorIconWrapper = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;

  circle,
  path {
    stroke: ${colors.errorRed};
  }
`

export const Message = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  div:first-child {
    margin-right: 16px;
  }
`
