import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

export const getErrorMessage = (title, fields) => {
  switch (title) {
    case 'Member Exists':
      return fields.formContactExistsErrorMessage
    case 'Invalid Resource':
      return fields.formInvalidEmailErrorMessage
    default:
      return title
  }
}

export const validateInput = input => {
  const errors: { required?: boolean; invalidEmail?: boolean } = {}
  if (input.required && input.value === '') {
    errors.required = true
  }

  switch (input.type) {
    case 'email':
      if (input.value !== '' && !input.value.match(/^.+@.+\..+$/))
        // todo: make the regex more robust
        errors.invalidEmail = true
      break
    case 'checkbox':
      if (input.required && !input.checked) errors.required = true
  }
  return errors
}

export const hasErrors = (errors, submitted, touched) => {
  if (!errors || (!submitted && !touched)) return false
  return Object.keys(errors).length !== 0
}

export const formatTextWithLines = (string, style?: any) => {
  return formatHtmlText(string, style).replace(/(?:\r\n|\r|\n)/g, '<br/>')
}
