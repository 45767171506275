import get from 'lodash/get'

/**
 * Categorize questions into products
 * @param {{ items: Array, total: Number }} products
 * @param {Object} relation
 * @param {Array} results
 * @returns {Array}
 */
export const mapToProducts = (products, relation, results) => {
  if (results.length === 0) {
    return []
  }

  const map = products.items.reduce((map, { fields }) => {
    map[fields.slug] = {
      ...fields,
      items: [],
    }
    return map
  }, {})

  results.forEach(item => {
    const slug = maybe(() => relation[item.sys.id], 'blue-lagoon')
    map[slug].items.push(item)
  })

  return Object.keys(map)
    .map(key => map[key])
    .filter(filter => filter.items.length)
}

export const maybe = (validator, defaultValue = void 0) => {
  try {
    const value = validator()
    return value == null ? defaultValue : value
  } catch {
    return defaultValue
  }
}

/**
 * Calculates average read time, assuming 150 words read per minute
 * @param {Array<Object>} textSections
 * @returns {string} calculated minute count in legible string
 */
export const estimatedReadTime = content => {
  if (!content) {
    return null
  }

  const textSections = []
  content.content.forEach(item => {
    item.content.forEach(subItem => {
      if (subItem.nodeType === 'text') {
        textSections.push(subItem.value)
      }
    })
  })

  const wordCount = textSections.reduce((prev, curr) => {
    const words = curr.split(' ')
    return prev + words.length
  }, 0)

  return Math.ceil(wordCount / 150)
}

export const getRatio = image => {
  if (!image) {
    return 0
  }
  const { height, width } = get(image, 'fields.file.details.image')
  return height / width
}
