import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import fastdom from 'fastdom'
import throttle from 'lodash/throttle'
import scroll from 'scroll'
import styled from 'styled-components'

import { durations } from '../../constants/durations'
import { zIndex } from '../../constants/zIndex'
import { PageStateContext } from '../../context/PageStateContext'
import { mixins } from '../../utils/mixins'
import { NextPrevious } from './NextPrevious'
import { PageControl } from './PageControl'
import { ScrollContainer } from './ScrollContainer'

type CarouselProps = {
  children: ReactNode
  minItemWidth?: any
  minItemWidthFrom?: any
  minItemWidthTo?: any
  pageMargin?: any
  endWidth?: any
  width?: any
  render?: any
  renderControls?: any
  carouselContainerCss?: any
  scrollContainerCss?: any
  callback?: any
  forceCurrentItem?: any
  initialItem?: any
  scrollKey?: any
  scrollDuration?: number
}

const isTouchDevice = () =>
  'ontouchstart' in window || Boolean(navigator.maxTouchPoints)

function easeInOutCubic(t) {
  return --t * t * t + 1
}

export const CarouselContainer = styled.div`
  position: relative;
  z-index: ${zIndex.above};
  ${mixins.disableScrollbarOnMobile()};
`

export const Carousel: React.FC<CarouselProps> = ({
  children,
  minItemWidth,
  minItemWidthFrom,
  minItemWidthTo,
  pageMargin = 1,
  endWidth = 2560,
  render,
  renderControls = true,
  carouselContainerCss,
  scrollContainerCss,
  callback,
  forceCurrentItem = null,
  width,
  initialItem = 0,
  scrollKey,
  scrollDuration = durations.long,
}) => {
  const [currentPage, setCurrentPage] = useState(initialItem ?? 0)
  const [itemWidth, setItemWidth] = useState(0)
  const [perPage, setPerPage] = useState(null)
  const [itemCount, setItemCount] = useState(React.Children.count(children))
  const containerRef = useRef(null)
  const context = useContext(PageStateContext)

  const touchScrolling = useRef(true)

  const stateKey = scrollKey ? `carousel:${scrollKey}` : null

  useEffect(() => {
    const onResize = throttle(() => {
      fastdom.measure(() => {
        if (!containerRef.current) return
        const { firstChild } = containerRef.current
        if (firstChild) {
          const itemStyle = window.getComputedStyle(firstChild)
          const width =
            parseFloat(itemStyle.width) + parseFloat(itemStyle.paddingRight) * 2
          const itemsPerPage = Math.floor(window.innerWidth / width)
          fastdom.mutate(() => {
            setItemWidth(width)
            setPerPage(itemsPerPage)
          })
        }
      })
    }, 250)

    window.addEventListener('resize', onResize)
    onResize()
    touchScrolling.current = isTouchDevice()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    setItemCount(React.Children.count(children))
  }, [children])

  useEffect(() => {
    const initialPage = Math.floor(initialItem / perPage) || 0
    const savedPage = stateKey ? context.getState(stateKey) : initialPage
    scrollTo(savedPage, true)
  }, [perPage])

  useEffect(() => {
    if (forceCurrentItem !== null) {
      scrollTo(forceCurrentItem)
    }
  }, [forceCurrentItem])

  const scrollTo = (page, instant = false) => {
    if (!containerRef.current) return

    const pageCount = Math.ceil(itemCount / perPage)
    const currentPage = Math.max(0, Math.min(pageCount - 1, page))
    const itemIndex = Math.max(
      0,
      Math.min(itemCount - perPage, currentPage * perPage)
    )
    const offset = itemIndex * itemWidth

    if (instant) {
      containerRef.current.scrollLeft = offset
    } else {
      scroll.left(containerRef.current, offset, {
        duration: scrollDuration,
        ease: easeInOutCubic,
      })
    }

    if (!touchScrolling.current) {
      setCurrentPage(currentPage)
    }
  }

  const scrollBy = delta => scrollTo(currentPage + delta)

  const onScroll = throttle(() => {
    if (!touchScrolling.current) return
    checkPageFromScroll()
  }, 250)

  const checkPageFromScroll = () => {
    if (!containerRef.current || perPage === null) return

    const scrollLeft = containerRef.current.scrollLeft
    const pageCount = Math.ceil(itemCount / perPage)
    let closestPage = 0
    let closestDistance = Infinity

    for (let page = 0; page < pageCount; page++) {
      const pagePosition =
        Math.min(itemCount - perPage, page * perPage) * itemWidth
      const distance = Math.abs(scrollLeft - pagePosition)
      if (distance < closestDistance) {
        closestDistance = distance
        closestPage = page
      }
    }

    setCurrentPage(closestPage)
  }

  const props = {
    scrollTo,
    scrollBy,
    touchScrolling: touchScrolling.current,
    onContainerScroll: onScroll,
    containerRef,
    minItemWidthFrom: minItemWidthFrom || minItemWidth,
    minItemWidthTo: minItemWidthTo || minItemWidth,
    width,
    itemCount,
    perPage,
    renderControls,
    carouselContainerCss,
    scrollContainerCss,
    pageCount: perPage ? Math.ceil(itemCount / perPage) : 0,
    currentPage,
    pageMargin,
    endWidth,
    children,
    callback,
    forceCurrentItem,
  }

  if (typeof render === 'function') {
    return render(props)
  }

  return (
    <Fragment>
      <CarouselContainer data-cy="carousel-container">
        <ScrollContainer {...props}>{children}</ScrollContainer>
        <NextPrevious
          pageCount={props.pageCount}
          pageMargin={props.pageMargin}
          currentPage={props.currentPage}
          scrollBy={props.scrollBy}
          touchScrolling={props.touchScrolling}
          callback={props.callback}
        />
      </CarouselContainer>
      {renderControls && <PageControl {...props} />}
    </Fragment>
  )
}
